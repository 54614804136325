import NextLink from "next/link";
import { Box, Stack, Tooltip, Link, ListItemText } from "@mui/material";
import { Iconify } from "../../../../fe-ui/atoms/Iconify";
import { NavItemProps } from "../types";
import { StyledItem, StyledIcon, StyledDotIcon } from "./styles";

export default function NavItem({ item, depth, open, active, isExternalLink, ...other }: NavItemProps) {
  const { title, path, icon = "", info, children, disabled, caption } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={`${title}`}
        secondary={
          caption && (
            <Tooltip title={`${caption}`} placement={"top-start"}>
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: "span",
          variant: active ? "subtitle2" : "body2"
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: "caption"
        }}
      />

      {info && (
        <Box component={"span"} sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  // color={theme.palette.text.primary}

  const renderItem = () => {
    // Language link
    // looked experimental - switched off
    // eslint-disable-next-line no-constant-condition
    if (false) {
      return (
        <Link href={path}>
          <Stack direction={"row"} sx={{ my: 2 }}>
            {icon}&nbsp;&nbsp;{title}
          </Stack>
        </Link>
      );
    }

    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target={"_blank"} rel={"noopener"} underline={"none"}>
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={NextLink} href={path} underline={"none"}>
        {renderContent}
      </Link>
    );
  };

  return renderItem();
}
